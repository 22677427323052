var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "block" }, [
    true
      ? _c(
          "form",
          {
            staticClass: "block__form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.forgotPass.apply(null, arguments)
              },
            },
          },
          [
            _c("p", { staticClass: "block__form__info" }, [
              _vm._v(
                "\n      Para redefinir sua senha preencha o campo abaixo:\n    "
              ),
            ]),
            _c("br"),
            _c("v-textbox", {
              staticClass: "block__form__password",
              attrs: {
                disabled: _vm.disableFields,
                label: "Insira o CPF ou CNPJ",
                name: "cpf_ou_cnpj",
                mask: ["###.###.###-##", "##.###.###/####-##"],
                placeholder: "CPF ou CNPJ",
                required: "",
              },
              model: {
                value: _vm.document,
                callback: function ($$v) {
                  _vm.document = $$v
                },
                expression: "document",
              },
            }),
            _c(
              "div",
              {
                staticClass: "block__form__actions",
                staticStyle: { display: "flex", "flex-direction": "column" },
              },
              [
                _vm.viewButtonRequestCodes
                  ? _c("v-button", {
                      attrs: {
                        label: "Solicitar códigos",
                        type: "submit",
                        disabled: _vm.disableFields,
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "block__form__actions__back",
                        on: { click: _vm.close },
                      },
                      [_vm._v("\n          Cancelar\n        ")]
                    ),
                    _vm.showResetPass && !_vm.phoneField
                      ? _c(
                          "a",
                          {
                            staticClass: "block__form__actions__back",
                            on: { click: _vm.forgotPass },
                          },
                          [
                            _vm._v(
                              "\n          Não recebi meu código\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.showResetPass
      ? _c(
          "form",
          {
            staticClass: "block__form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.resetPass.apply(null, arguments)
              },
            },
          },
          [
            !_vm.phoneField && !_vm.emailField
              ? _c("p", { staticClass: "block__form__info" }, [
                  _vm._v(
                    "\n      Preencha o campo abaixo com o código recebido:\n    "
                  ),
                ])
              : _vm._e(),
            !_vm.phoneField && !_vm.emailField ? _c("br") : _vm._e(),
            _c(
              "div",
              { staticStyle: { display: "flex", "flex-direction": "column" } },
              [
                !_vm.phoneField
                  ? _c(
                      "label",
                      {
                        staticClass: "textbox__label label_code",
                        attrs: { for: "código_celular" },
                      },
                      [
                        _c("span", { staticClass: "label_code" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.cellphoneLabel) +
                              "\n        "
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.phoneAndEmail.phone) +
                              "\n        "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                !_vm.phoneField
                  ? _c("v-textbox", {
                      staticClass: "block__form",
                      attrs: {
                        name: "código_celular",
                        mask: "######",
                        required: "",
                      },
                      model: {
                        value: _vm.phoneCode,
                        callback: function ($$v) {
                          _vm.phoneCode = $$v
                        },
                        expression: "phoneCode",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            !_vm.phoneField
              ? _c("v-button", {
                  staticClass: "mt-3",
                  attrs: {
                    label: "Ok",
                    type: "button",
                    disabled: _vm.phoneCode.length < 6,
                  },
                  on: { click: _vm.handlePhoneCodeConfirmation },
                })
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "mb-3",
                staticStyle: { display: "flex", "flex-direction": "column" },
              },
              [
                _vm.phoneField && !_vm.emailField
                  ? _c(
                      "label",
                      {
                        staticClass: "textbox__label label_code",
                        attrs: { for: "código_celular" },
                      },
                      [
                        _c("span", { staticClass: "label_code" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.emailLabel) +
                              "\n        "
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.phoneAndEmail.email) +
                              "\n        "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.phoneField && !_vm.emailField
                  ? _c("v-textbox", {
                      staticClass: "block__form",
                      attrs: {
                        name: "código email",
                        mask: "######",
                        required: "",
                      },
                      model: {
                        value: _vm.emailCode,
                        callback: function ($$v) {
                          _vm.emailCode = $$v
                        },
                        expression: "emailCode",
                      },
                    })
                  : _vm._e(),
                _vm.phoneField && !_vm.emailField
                  ? _c("v-button", {
                      staticClass: "mt-3",
                      attrs: {
                        label: "Ok",
                        type: "button",
                        disabled: _vm.emailCode.length < 6,
                      },
                      on: { click: _vm.handleEmailCodeConfirmation },
                    })
                  : _vm._e(),
              ],
              1
            ),
            this.emailField
              ? _c("v-user-password", {
                  attrs: { data: _vm.password, responsive: false },
                })
              : _vm._e(),
            this.emailField
              ? _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c("vue-recaptcha", {
                      attrs: { sitekey: _vm.site_key },
                      on: { verify: _vm.mxVerify },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            this.emailField
              ? _c(
                  "div",
                  { staticClass: "block__form__actions" },
                  [_c("v-button", { attrs: { label: "Atualizar Senha" } })],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }